/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import ReactJson from 'react-json-view';
import queryString from 'query-string';
import './app.sass';

const IS_PRODUCTION = process.env.NODE_ENV !== 'development';

const API_URL = IS_PRODUCTION ? '/api' : 'http://localhost:4000/api';

const COLORS = ['#FF00FF', '#C71585', '#FFD700', '#00FA9A', '#20B2AA', '#7FFFD4', '#D2691E'];

// TODO: OnWindowFocus => fetch every second
// TODO: no pageload bewtween apps
// TODO: Clear app logs
// TODO: Pretty loader
// TODO: Autoscroller
// TODO: Favicon and title
// TODO: Toggle collapsed

const App = () => {
  const [logs, setLogs] = useState([]);
  const [apps, setApps] = useState([]);
  const [clearDate, setClearDate] = useState(0);
  const [loading, setLoading] = useState(true);

  const qs = queryString.parse(window.location.search);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${API_URL}/logs${qs.app ? `/${qs.app}` : ''}/${qs.key}`);
      setLogs((result.data && result.data.logs) || []);
      setApps(
        (result.data && result.data.apps.sort().map((app, index) => ({ color: COLORS[index], name: app }))) || [],
      );
      setLoading(false);
    };
    fetchData();
  }, [qs.key, qs.app, clearDate]);

  const onAppClick = (e, app) => {
    e.preventDefault();
    const stringified = queryString.stringify({ ...qs, app });
    window.location.search = stringified;
  };

  const onClearLogs = async e => {
    e.preventDefault();
    await axios(`${API_URL}/clearlogs${qs.app ? `/${qs.app}` : ''}/${qs.key}`);
    setClearDate(Date.now());
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }
  return (
    <div className="app">
      <nav>
        <ul>
          <li>
            <a href="" onClick={e => onAppClick(e, null)}>
              ALL LOGS
            </a>
          </li>
          {apps.map(({ name, color }) => (
            <li key={name}>
              <a href="" onClick={e => onAppClick(e, name)} className={qs.app === name ? 'active' : ''}>
                <span className="color" style={{ background: color }} /> <span className="label">{name}</span>
              </a>
            </li>
          ))}
        </ul>
        <button type="button" onClick={onClearLogs} className="clear-button">
          Clear logs{qs.app ? ` (${qs.app})` : ''}
        </button>
      </nav>

      <main>
        {logs.map(item => {
          const { timestamp, _id, app, key, ...log } = item; // eslint-disable-line
          const appObj = apps.find(a => a.name === app);
          return (
            <div key={_id} className="log-item">
              <div className="date">
                {moment().diff(timestamp, 'days') !== 0 ? (
                  <>
                    {moment(timestamp).format('DD/MM/YY')}
                    <br />
                  </>
                ) : (
                  ''
                )}{' '}
                {moment(timestamp).format('HH:mm:ss')}
              </div>
              <div className="app" style={{ color: appObj.color }}>
                {appObj.name}
              </div>
              <div className="log">
                <ReactJson src={log} theme="monokai" name={false} iconStyle="circle" collapsed={false} />
              </div>
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default App;
